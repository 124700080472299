import React, { useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import AppModal from "./AppModal";
import { ErrorText } from "../atoms/UI/Text";
import styled from "@emotion/styled";
import { InputBorder } from "../atoms/UI/Inputs";
import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import PassswordForm from "../forms/PassswordForm";
import { instance } from "../../api/instance.axios";
import { useAppDispatch } from "../../hooks/useAppDisptach";
import { actions } from "../../store/slice/profile.slice";
import useValidAxiosError from "../../hooks/useValidAxiosError";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { localStorageWrapper } from "../../config/storage";
import { getUserInformationThunk } from "../../store/thunk/system.thunk";
import { assetsApi } from "../../api/assets.api";

interface IProps {
  clearModal: () => void;
  title: string;
}

interface IFormInput {
  login: string;
  password: string;
  code?: string;
}

const LoginModal: React.FC<IProps> = ({ clearModal, title }) => {
  const [step, setStep] = useState<number>(1);
  const { control, handleSubmit, setValue } = useForm<IFormInput>({
    defaultValues: {
      login: "",
      password: "",
      code: "",
    },
    mode: "onTouched",
  });

  const { fetchError } = useValidAxiosError();
  const loader = useTypedSelector((state) => state.profile.loading);
  const dispatch = useAppDispatch();

  const onSubmitLogin = useCallback(
    async (data: IFormInput) => {
      if (!data.login || !data.password) {
        return;
      }

      dispatch(actions.setLoading(true));
      try {
        const res = await assetsApi.login(data.login, data.password);

        if (res) {
          const { accessToken, id, phone, email, fio } = res;
          localStorageWrapper.set("accessToken", accessToken);
          localStorageWrapper.set("id", id);
          localStorageWrapper.set("phone", phone);
          localStorageWrapper.set("email", email);
          localStorageWrapper.set("fio", fio);

          dispatch(getUserInformationThunk());
          clearModal();
        }
      } catch (err: any) {
        fetchError(err);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [dispatch, fetchError, clearModal]
  );

  const onSubmitRegisterPhone = useCallback(
    async (data: IFormInput) => {
      if (!data.login) {
        return;
      }

      dispatch(actions.setLoading(true));
      try {
        await instance.post("/auth/signup-step1", {
          phone: data.login,
        });
        setStep(3);
      } catch (err: any) {
        fetchError(err);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [dispatch, fetchError]
  );

  const onSubmitRegisterCode = useCallback(
    async (data: IFormInput) => {
      if (!data.login || !data.password || !data.code) {
        return;
      }

      dispatch(actions.setLoading(true));
      try {
        const res = await instance.post("/auth/signup-step2", {
          phone: data.login,
          password: data.password,
          code: data.code,
        });

        if (res && res.data) {
          const { accessToken, id, phone, email, fio } = res.data;
          localStorageWrapper.set("accessToken", accessToken);
          localStorageWrapper.set("id", id);
          localStorageWrapper.set("phone", phone);
          localStorageWrapper.set("email", email);
          localStorageWrapper.set("fio", fio);

          dispatch(getUserInformationThunk());

          clearModal();
        }
      } catch (err: any) {
        fetchError(err);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [dispatch, clearModal, fetchError]
  );

  const goToRegister = () => {
    setStep(2);
  };

  const goBackToLogin = () => {
    setStep(1);
    setValue("password", "");
    setValue("code", "");
  };

  const getTitle = () => {
    switch (step) {
      case 1:
        return "Вход";
      case 2:
        return "Регистрация — шаг 1: Введите номер";
      case 3:
        return "Регистрация — шаг 2: Введите код и пароль";
      default:
        return "Вход";
    }
  };

  return (
    <>
      <AppModal
        styles={{
          margin: "0 20px",
        }}
        name={"login"}
        onClose={clearModal}
      >
        <div className="title-block">{getTitle()}</div>

        {step === 1 && (
          <form onSubmit={handleSubmit(onSubmitLogin)}>
            <Controller
              name="login"
              control={control}
              rules={{ required: "Номер обязательно для заполнения" }}
              render={({ field, fieldState }) => (
                <>
                  <InputBorder
                    {...field}
                    type="text"
                    placeholder="Номер телефона"
                    bottom="1px solid #27457C4A"
                    style={{ marginBottom: "1rem", borderRadius: "0" }}
                  />
                  {fieldState.error && (
                    <ErrorText style={{ margin: "0" }}>
                      {fieldState.error.message}
                    </ErrorText>
                  )}
                </>
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{ required: "Пароль обязательно для заполнения" }}
              render={({ field, fieldState }) => (
                <>
                  <PassswordForm field={field} />
                  {fieldState.error && (
                    <ErrorText style={{ margin: "0" }}>
                      {fieldState.error.message}
                    </ErrorText>
                  )}
                </>
              )}
            />

            <Buttons className="flex gap-1_2">
              <PrimaryButtonStyled type="submit" disabled={loader}>
                Войти
              </PrimaryButtonStyled>
              <PrimaryButtonStyled type="button" onClick={goToRegister}>
                Регистрация
              </PrimaryButtonStyled>
            </Buttons>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleSubmit(onSubmitRegisterPhone)}>
            <Controller
              name="login"
              control={control}
              rules={{ required: "Номер обязательно для заполнения" }}
              render={({ field, fieldState }) => (
                <>
                  <InputBorder
                    {...field}
                    type="text"
                    placeholder="Номер телефона"
                    bottom="1px solid #27457C4A"
                    style={{ marginBottom: "1rem", borderRadius: "0" }}
                  />
                  {fieldState.error && (
                    <ErrorText style={{ margin: "0" }}>
                      {fieldState.error.message}
                    </ErrorText>
                  )}
                </>
              )}
            />

            <Buttons className="flex gap-1_2">
              <PrimaryButtonStyled type="submit" disabled={loader}>
                Отправить код
              </PrimaryButtonStyled>

              <PrimaryButtonStyled type="button" onClick={goBackToLogin}>
                Назад
              </PrimaryButtonStyled>
            </Buttons>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handleSubmit(onSubmitRegisterCode)}>
            <Controller
              name="login"
              control={control}
              render={({ field }) => (
                <InputBorder
                  {...field}
                  type="text"
                  placeholder="Номер телефона"
                  readOnly
                  bottom="1px solid #27457C4A"
                  style={{ marginBottom: "1rem", borderRadius: "0" }}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{ required: "Пароль обязательно для заполнения" }}
              render={({ field, fieldState }) => (
                <>
                  <PassswordForm field={field} />
                  {fieldState.error && (
                    <ErrorText style={{ margin: "0" }}>
                      {fieldState.error.message}
                    </ErrorText>
                  )}
                </>
              )}
            />

            <Controller
              name="code"
              control={control}
              rules={{ required: "Код обязательно для заполнения" }}
              render={({ field, fieldState }) => (
                <>
                  <InputBorder
                    {...field}
                    type="text"
                    placeholder="Код подтверждения"
                    bottom="1px solid #27457C4A"
                    style={{ marginBottom: "1rem", borderRadius: "0" }}
                  />
                  {fieldState.error && (
                    <ErrorText style={{ margin: "0" }}>
                      {fieldState.error.message}
                    </ErrorText>
                  )}
                </>
              )}
            />

            <Buttons className="flex gap-1_2">
              <PrimaryButtonStyled type="submit" disabled={loader}>
                Регистрация
              </PrimaryButtonStyled>
            </Buttons>
          </form>
        )}
      </AppModal>
    </>
  );
};

const Buttons = styled.div`
  margin-top: 2rem;

  button {
    width: 100%;
  }
`;

export default LoginModal;
